<template>
    <uw-content title="合同采购">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" icon="el-icon-plus" @click="$refs.orderCreate.open({ type: 'contract' })" >新的采购订单</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.number" clearable  size="mini" placeholder="采购单编号查询"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 审批状态 -->
                    <vxe-column width="90" title="审批状态" align="center">
                        <template #default="{ row }">
                            <el-tag v-if="row.workflow_state == 0" size="mini" effect="dark" type="info">未审核</el-tag>
                            <el-tag v-if="row.workflow_state == 1" size="mini" effect="dark" type="warning">待审核</el-tag>
                            <el-tag v-if="row.workflow_state == 2" size="mini" effect="dark" >审核中</el-tag>
                            <el-tag v-if="row.workflow_state == 3" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                            <el-tag v-if="row.workflow_state == 4" size="mini" effect="dark" type="success">已通过</el-tag>
                            <el-tag v-if="row.workflow_state == 5" size="mini" effect="dark" type="warning">待撤销</el-tag>
                            <el-tag v-if="row.workflow_state == 6" size="mini" effect="dark" type="warning">撤销中</el-tag>
                            <el-tag v-if="row.workflow_state == 7" size="mini" effect="dark" type="danger">撤销失败</el-tag>
                            <el-tag v-if="row.workflow_state == 8" size="mini" effect="dark" type="danger">已撤销</el-tag>
                        </template>
                    </vxe-column>
                    
                    <!-- 采购单编号 -->
                    <vxe-column min-width="160" title="采购单编号">
                        <template #default="{ row }">
                            <span @click="$refs.orderDrawer.Open(row.id)" class="el-span-primary">{{ row.number }}</span>
                        </template>
                    </vxe-column>

                    <!-- 合同编号 -->
                    <vxe-column min-width="160" title="合同编号">
                        <template #default="{ row }">
                            <span @click="$refs.contractDrawer.Open(row.contract_id)" class="el-span-primary">{{ row.contract.number }}-{{ row.contract.name }}</span>
                        </template>
                    </vxe-column>

                    <!-- 交货时间 -->
                    <vxe-column min-width="100" field="delivery_date" title="交货时间"></vxe-column>
                    
                    <!-- 基础信息 -->
                    <vxe-column min-width="100" field="date" title="采购日期"></vxe-column>
                    <vxe-column min-width="100" field="money_estimate" title="采购金额(预)"></vxe-column>
                    <vxe-column min-width="100" field="money_actual" title="采购金额(实)"></vxe-column>
                    <vxe-column min-width="120" field="describe" title="备注"></vxe-column>

                    <!-- 系统信息 -->
                    <vxe-column width="140" field="updated_at" title="创建时间"></vxe-column>
                    <vxe-column width="80" field="updated_user.name" title="创建人"></vxe-column>
                    <vxe-column width="140" field="created_at" title="更新时间"></vxe-column>
                    <vxe-column width="80" field="created_user.name" title="更新人"></vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <ims-product-order-create ref="orderCreate" @onChange="TableSearch" />
        <ims-product-order-drawer ref="orderDrawer" @onChange="TableSearch" />
        <crm-contract-drawer ref="contractDrawer" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 窗口
            loading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                number: null,
                type: 'contract'
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 供应商 - 查询
        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/ims/product-order/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_user', 'updated_user', 'contract']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        }
    }

}
</script>